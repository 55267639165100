import MuiToolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

export const Toolbar = withStyles({
  root: {
    justifyContent: 'space-between',
  },
})(MuiToolbar);

export const OpenedMenuIconButton = withStyles({
  root: {
    margin: '12px 4px 0',
  },
})(IconButton);
