/* eslint-disable import/prefer-default-export */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';

import { DialogContext, ItemExtended } from '../../../ui/dialoglist';
import LocalizedLink from '../../localized-link';
import genKey from '../../../utils/gen-key';
import useData from '../useData';
import NavContext from '../context';
import { getSelectedCountry } from '../utils';
import useStyles from '../styled';
import { RecursiveMenu } from '../RecursiveMenu';
import Arrow from '../Arrow';
import { colors } from '../../../ui/common';

import { MenuDrawer } from './MenuDrawer';
import {
  MenuMainItem,
  Accordion,
  TallAccordionSummary,
  ShiftedAccordionDetails,
} from './styled';

export function NavTablet({ transparent }) {
  const { items, languages, support, campaigns } = useData();
  const { countries, onChangeCountry, getCountry, short, logoTo } =
    useContext(NavContext);

  const classes = useStyles();

  const countryCode = getCountry();

  return (
    <MenuDrawer short={short} logoTo={logoTo} transparent={transparent}>
      <div className={classes.tabletMenuWrap}>
        {/* TODO Uncomment to enable about link */}
        {/* <MenuMainItem>
        <LocalizedLink to="about">
          <FormattedMessage id="nav.about" />
        </LocalizedLink>
      </MenuMainItem> */}

        <Accordion>
          <TallAccordionSummary
            expandIcon={<Arrow color={colors.color01} />}
            aria-controls="mobile-navigation-evapolar-family-content"
            id="mobile-navigation-evapolar-family-header"
          >
            <FormattedMessage id="nav.ev_fam" />
          </TallAccordionSummary>
          <ShiftedAccordionDetails>
            <RecursiveMenu itemList={items} style={{ fontSize: '16px' }} />
          </ShiftedAccordionDetails>
        </Accordion>

        <MenuMainItem>
          <LocalizedLink to="technology">
            <FormattedMessage id="nav.technology" />
          </LocalizedLink>
        </MenuMainItem>

        <MenuMainItem>
          <LocalizedLink to="about-us">
            <FormattedMessage id="nav.about_us" />
          </LocalizedLink>
        </MenuMainItem>

        <MenuMainItem>
          <Link href="/blog" color="inherit">
            <FormattedMessage id="nav.blog" />
          </Link>
        </MenuMainItem>

        <Accordion>
          <TallAccordionSummary
            expandIcon={<Arrow />}
            aria-controls="mobile-navigation-chillocean-content"
            id="mobile-navigation-chillocean-header"
          >
            <FormattedMessage id="nav.campaigns" />
          </TallAccordionSummary>
          <ShiftedAccordionDetails>
            <RecursiveMenu itemList={campaigns} style={{ fontSize: '16px' }} />
          </ShiftedAccordionDetails>
        </Accordion>

        <Accordion>
          <TallAccordionSummary
            expandIcon={<Arrow />}
            aria-controls="mobile-navigation-support-content"
            id="mobile-navigation-support-header"
          >
            <FormattedMessage id="footer.support" />
          </TallAccordionSummary>
          <ShiftedAccordionDetails>
            <RecursiveMenu itemList={support} style={{ fontSize: '16px' }} />
          </ShiftedAccordionDetails>
        </Accordion>

        <Accordion>
          <TallAccordionSummary
            expandIcon={<Arrow />}
            aria-controls="mobile-navigation-languages-content"
            id="mobile-navigation-languages-header"
          >
            <FormattedMessage id="nav.lang" />
          </TallAccordionSummary>
          <ShiftedAccordionDetails>
            <RecursiveMenu itemList={languages} style={{ fontSize: '16px' }} />
          </ShiftedAccordionDetails>
        </Accordion>

        <Accordion>
          <TallAccordionSummary
            expandIcon={<Arrow />}
            aria-controls="mobile-navigation-region-content"
            id="mobile-navigation-region-header"
          >
            <FormattedMessage id="nav.country" />
          </TallAccordionSummary>
          <ShiftedAccordionDetails>
            <DialogContext.Provider
              // TODO Wrap with useMemo()?
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              value={{
                selectedValue: getSelectedCountry(countries, countryCode),
                onSelect: (value) => onChangeCountry(value.code2),
                translate: false,
              }}
            >
              {countries.map((value, i) => (
                <ItemExtended
                  key={genKey()}
                  value={value}
                  last={countries.length - 1 === i}
                />
              ))}
            </DialogContext.Provider>
          </ShiftedAccordionDetails>
        </Accordion>
      </div>
    </MenuDrawer>
  );
}
NavTablet.propTypes = {
  transparent: PropTypes.bool,
};
NavTablet.defaultProps = {
  transparent: false,
};
