import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../ui/common';

export default function Arrow({ color }) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.996154 0.757579L5.23877 5.00024L9.48139 0.757579"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Arrow.propTypes = {
  color: PropTypes.string,
};
Arrow.defaultProps = {
  color: colors.color01,
};
