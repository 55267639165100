/* eslint-disable import/prefer-default-export */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Arrow from '../Arrow';

import { Accordion, AccordionSummary, AccordionDetails } from './styled';

export const RecursiveMenu = ({ itemList, style }) =>
  itemList.map((item) =>
    item.menu ? (
      <Accordion key={item.menu.title} style={style}>
        <AccordionSummary
          expandIcon={<Arrow />}
          aria-controls={`mobile-navigation-evapolar-family-${item.menu.title}-content`}
          id={`mobile-navigation-evapolar-family-${item.menu.title}-header`}
        >
          <FormattedMessage id={item.menu.title} />
        </AccordionSummary>
        <AccordionDetails>
          <RecursiveMenu itemList={item.menu.items} style={style} />
        </AccordionDetails>
      </Accordion>
    ) : (
      item.render(item, style)
    )
  );
