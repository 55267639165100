/* eslint-disable import/prefer-default-export */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MuiAppBar from '@material-ui/core/AppBar';

import { scrolledColor } from '../styled';

const useAppBarStyles = makeStyles({
  root: {
    background: (props) => (props.transparent ? 'transparent' : '#fff'),
    boxShadow: (props) =>
      props.transparent ? 'none' : '0px 7px 20px rgba(0, 0, 0, 0.05)',
    color: (props) => (props.transparent ? '#fff' : scrolledColor),
    minHeight: '88px',
    justifyContent: 'center',
    zIndex: 50,
  },
});
export const AppBar = forwardRef((props, ref) => {
  const classes = useAppBarStyles(props);
  const { transparent, ...rest } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAppBar className={classes.root} {...rest} ref={ref} />;
});
AppBar.propTypes = {
  transparent: PropTypes.bool,
};
AppBar.defaultProps = {
  transparent: false,
};
