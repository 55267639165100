import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { calculateItemsCount } from '../../../utils/api/basket';
import NavContext from '../context';
import useStyles from './styled';
import bucketImg from '../../../images/nav/icon-basket.svg';

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
function Basket({ transparent }) {
  const classes = useStyles();

  const { basket, openBasket } = useContext(NavContext);

  const basketClass = classnames(
    classes.icon,
    !transparent && classes.iconScrolled
  );

  return (
    <div className={classes.wrap} role="button" onClick={openBasket}>
      <img src={bucketImg} alt="basket" className={basketClass} />
      <div className={classes.title}>
        {basket.length > 0 && calculateItemsCount(basket)}
      </div>
    </div>
  );
}
/* eslint-enable jsx-a11y/interactive-supports-focus */
/* eslint-enable jsx-a11y/click-events-have-key-events */

Basket.propTypes = {
  transparent: PropTypes.bool,
};
Basket.defaultProps = {
  transparent: false,
};

export default Basket;
