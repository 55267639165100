/* eslint-disable import/prefer-default-export */

import classnames from 'classnames';
import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import globalImg from '../../../images/nav/region-global.svg';
import Hideable from '../../../utils/hideable';
import DialogList from '../../../ui/dialoglist';
import useData from '../useData';
import NavContext from '../context';
import LocalizedLink from '../../localized-link';
import Basket from '../Basket';
import { AppBar } from '../AppBar';
import useClickOutside from '../../../hooks/use-click-outside';
import { getSelectedCountry } from '../utils';
import useStyles from '../styled';
import { RecursiveMenu } from '../RecursiveMenu';
import { colors } from '../../../ui/common';
import Logo from '../../Logo';

import {
  MenuItem,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from './styled';

export function NavDesktop({ transparent }) {
  const { items, languages, support, campaigns } = useData();
  const { countries, onChangeCountry, getCountry, short, logoTo } =
    useContext(NavContext);
  const [expanded, setExpanded] = useState(null);
  const wrapperRef = useRef();
  const collapseAllAccordions = () => {
    setExpanded(null);
  };
  useClickOutside(wrapperRef, collapseAllAccordions);
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const notOnTop = useScrollTrigger({ disableHysteresis: true });
  const transparentOnTop = transparent && !notOnTop;

  const countryCode = getCountry();
  const locale = useSelector((state) => state.locale.picked);

  let localeIndex = languages.findIndex((v) => v.code === locale);

  if (localeIndex === -1) localeIndex = 0;

  const classes = useStyles({ transparent: transparentOnTop });

  const titleClass = classnames(classes.title);

  return (
    <Hideable
      callback={(visible) => {
        if (!visible) {
          collapseAllAccordions();
        }
      }}
    >
      <AppBar transparent={transparentOnTop} ref={wrapperRef}>
        <Toolbar>
          {short ? (
            <>
              <div />
              <div
                className={classnames(
                  classes.navCenter,
                  classes.fixCenterAlign
                )}
              >
                <LocalizedLink to={logoTo}>
                  <Logo
                    textColor={
                      transparentOnTop ? colors.color15 : colors.color14
                    }
                  />
                </LocalizedLink>
              </div>
              <div className={classes.navRight}>
                <Basket transparent={transparentOnTop} />
              </div>
            </>
          ) : (
            <>
              <div>
                <LocalizedLink to={logoTo}>
                  <Logo
                    textColor={
                      transparentOnTop ? colors.color15 : colors.color14
                    }
                  />
                </LocalizedLink>
              </div>
              <div className={classes.navCenter}>
                <MenuItem>
                  <Accordion
                    expanded={expanded === 'desktop-navigation-evapolar-family'}
                    onChange={handleChange(
                      'desktop-navigation-evapolar-family'
                    )}
                  >
                    <AccordionSummary
                      transparent={transparentOnTop}
                      aria-controls="desktop-navigation-evapolar-family-content"
                      id="desktop-navigation-evapolar-family-header"
                    >
                      <FormattedMessage id="nav.ev_fam" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <RecursiveMenu
                        itemList={items}
                        style={{ fontSize: '12px' }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
                <MenuItem>
                  <LocalizedLink to="technology" className={titleClass}>
                    <FormattedMessage id="nav.technology" />
                  </LocalizedLink>
                </MenuItem>
                <MenuItem>
                  <LocalizedLink to="about-us" className={titleClass}>
                    <FormattedMessage id="nav.about_us" />
                  </LocalizedLink>
                </MenuItem>
                <MenuItem>
                  <Link href="/blog" className={titleClass}>
                    <FormattedMessage id="nav.blog" />
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Accordion
                    expanded={expanded === 'desktop-chill-ocean'}
                    onChange={handleChange('desktop-chill-ocean')}
                  >
                    <AccordionSummary
                      transparent={transparentOnTop}
                      aria-controls="desktop-chill-ocean-content"
                      id="desktop-chill-ocean-header"
                    >
                      <FormattedMessage id="nav.campaigns" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <RecursiveMenu
                        itemList={campaigns}
                        style={{ fontSize: '12px' }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
                <MenuItem>
                  <Accordion
                    expanded={expanded === 'desktop-navigation-support'}
                    onChange={handleChange('desktop-navigation-support')}
                  >
                    <AccordionSummary
                      transparent={transparentOnTop}
                      aria-controls="desktop-navigation-support-content"
                      id="desktop-navigation-support-header"
                    >
                      <FormattedMessage id="footer.support" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <RecursiveMenu
                        itemList={support}
                        style={{ fontSize: '12px' }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
              </div>
              <div className={classes.navRight}>
                <Accordion
                  expanded={expanded === 'desktop-navigation-languages-family'}
                  onChange={handleChange('desktop-navigation-languages-family')}
                >
                  <AccordionSummary
                    transparent={transparentOnTop}
                    aria-controls="desktop-navigation-languages-family-content"
                    id="desktop-navigation-languages-family-header"
                  >
                    {languages[localeIndex].shorten}
                  </AccordionSummary>
                  <AccordionDetails>
                    <RecursiveMenu
                      itemList={languages}
                      style={{ fontSize: '12px' }}
                    />
                  </AccordionDetails>
                </Accordion>

                <DialogList
                  defaultIcon={globalImg}
                  title={<FormattedMessage id="nav.country_choose" />}
                  customItems={countries}
                  scrolled={!transparentOnTop}
                  selectedValue={getSelectedCountry(countries, countryCode)}
                  onSelect={(value) => onChangeCountry(value.code2)}
                  fieldSelected="code2"
                />
                <Basket transparent={transparentOnTop} />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Hideable>
  );
}
NavDesktop.propTypes = {
  transparent: PropTypes.bool,
};
NavDesktop.defaultProps = {
  transparent: false,
};
