import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import intersectWith from 'lodash/intersectionWith';
import ActionType from '../../store/action-type';
import { NavTablet } from './NavTablet';
import { NavDesktop } from './NavDesktop';
import NavContext from './context';
import useApplyCountry from '../../hooks/use-apply-country';
import useBasket from '../../hooks/use-basket';
import Basket from '../Basket';
import { LaptopMax, LaptopMin } from '../../utils/mediaCheck';
import useLocale from '../../hooks/use-locale';
import applyLocale from '../../utils/api/applyLocale';
import {
  selectHasItemsLoaded,
  selectItemList,
} from '../../store/selectors/items';

const commonCountriesCodes = [
  'AU',
  'DE',
  'FR',
  'GB',
  'US',
  'ES',
  'IT',
  'NL',
  'BE',
  'CA',
  'SG',
  'AE',
];

const SHOW_CART_PARAM_MAPPING = new Map([
  ['false', false],
  ['true', true],
]);

const useQueryParams = () => {
  const hasItems = useSelector(selectHasItemsLoaded);
  const allItems = useSelector(selectItemList);
  const { clearItems, addItem } = useBasket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasItems) return;

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('empty_cart')) clearItems();

    const cartParam = urlSearchParams.get('cart');
    if (cartParam?.length) {
      cartParam.split(',').forEach((v) => {
        const item = allItems.find(({ id }) => id === Number(v));
        if (item) addItem(item, false);
      });
    }

    const showCartParam = urlSearchParams.get('show_cart');
    if (SHOW_CART_PARAM_MAPPING.has(showCartParam)) {
      dispatch({
        type: ActionType.Basket.Visible,
        payload: SHOW_CART_PARAM_MAPPING.get(showCartParam),
      });
    }
  }, [hasItems]);
};

function NavBar({ short, logoTo, transparent }) {
  const applyCountry = useApplyCountry();
  useQueryParams();
  const localeManager = useLocale();
  const { countries, basket, country } = useSelector((state) => ({
    countries: state.countries.list,
    basket: state.basket.list,
    country: state.countries.picked,
  }));
  const dispatch = useDispatch();

  const commonCountries = intersectWith(
    countries,
    commonCountriesCodes,
    (value, code) => code === value.code2
  );
  commonCountries.sort((a, b) => a.name.localeCompare(b.name));

  // TODO Wrap with useMemo()?
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state = {
    basket,
    countries: [
      {
        title: 'nav.country_common',
        items: commonCountries,
      },
      {
        title: 'nav.country_all',
        items: [...countries],
      },
    ],
    scrolled: true,
    short,
    logoTo,
    onChangeCountry: async (code) => {
      /* change country, apply some changes */
      await applyCountry(code);

      /* apply locale */
      applyLocale(localeManager, code);
    },
    onChangeLanguage: (code) => {
      localeManager(code);
    },
    getCountry: () => country || '',
    openBasket: () => {
      dispatch({ type: ActionType.Basket.Visible, payload: true });
    },
  };

  return (
    <NavContext.Provider value={state}>
      <LaptopMin>
        <NavDesktop transparent={transparent} />
      </LaptopMin>
      <LaptopMax>
        <NavTablet transparent={transparent} />
      </LaptopMax>
      <Basket checkout />
    </NavContext.Provider>
  );
}

NavBar.propTypes = {
  short: PropTypes.bool,
  logoTo: PropTypes.string,
  transparent: PropTypes.bool,
};

NavBar.defaultProps = {
  short: false,
  logoTo: '/',
  transparent: false,
};

export default NavBar;
