import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

export const Accordion = withStyles({
  root: {
    color: 'inherit',
    border: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
})(MuiAccordion);

export const RawAccordionSummary = withStyles({
  root: {
    fontWeight: 600,
    justifyContent: 'space-between',
    padding: 0,
  },
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);
export const AccordionSummary = styled(RawAccordionSummary)``;

export const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block',
  },
})(MuiAccordionDetails);

export const PageLinkWrapper = styled.div`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  min-height: 48px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

// https://css-tricks.com/overriding-default-button-styles/
export const LanguageItem = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: #fff;
  margin-bottom: 0px;
  cursor: pointer;
`;
