// TODO Move functionality to the hook(s) and delete file

import { languages } from '../../components/Navbar/useData';
import { getCountriesList } from './countries';

const LOCALE_BY_COUNTRY_CODE = {
  AU: 'en-AU',
  UK: 'en-UK',
  DE: 'de',
  FR: 'fr',
  ES: 'es',
  RU: 'ru',
};
const DEFAULT_LOCALE = 'en';

const isCountryExists = (countryCode) =>
  getCountriesList().some(
    (v) => v.code2 && v.code2.toLowerCase() === countryCode.toLowerCase()
  );

const applyLocale = (localeManager, countryCode) => {
  if (!countryCode || !isCountryExists(countryCode)) return;

  const locale =
    LOCALE_BY_COUNTRY_CODE[countryCode.toUpperCase()] || countryCode;
  const isKnownLocale = languages.some(
    (v) => v.code.toLowerCase() === locale.toLowerCase()
  );
  localeManager(isKnownLocale ? locale : DEFAULT_LOCALE);
};

export default applyLocale;
