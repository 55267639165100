import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';

import LocalizedLink from '../../localized-link';
import { PageLinkWrapper, LanguageItem } from './styled';

export const renderExternalPageLink = (item, style) => (
  <PageLinkWrapper key={item.name} bold={item.bold} style={style}>
    <Link href={item.href} color="inherit">
      <FormattedMessage id={item.name} />
    </Link>
  </PageLinkWrapper>
);

export const getRenderLanguageButton = (onChangeLanguage) =>
  function renderLanguageButton(item, style) {
    return (
      <PageLinkWrapper key={item.name} bold={item.bold} style={style}>
        <LanguageItem onClick={() => onChangeLanguage(item.code)}>
          <FormattedMessage id={item.name} />
        </LanguageItem>
      </PageLinkWrapper>
    );
  };

export const renderInternalPageLink = (item, style) => (
  <PageLinkWrapper key={item.name} bold={item.bold} style={style}>
    <LocalizedLink to={item.link}>
      <FormattedMessage id={item.name} />
    </LocalizedLink>
  </PageLinkWrapper>
);
