import React, { useEffect } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';

const noop = () => {};

function Hideable({ children, callback }) {
  const trigger = useScrollTrigger();
  const visible = !trigger;
  useEffect(() => {
    callback(visible);
  }, [visible]);

  return (
    <Slide appear={false} direction="down" in={visible}>
      {children}
    </Slide>
  );
}

Hideable.propTypes = {
  children: PropTypes.element.isRequired,
  callback: PropTypes.func,
};
Hideable.defaultProps = {
  callback: noop,
};

export default Hideable;
