import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiToolbar from '@material-ui/core/Toolbar';
import { scrolledColor } from '../styled';
import {
  AccordionSummary as RecursiveMenuAccordionSummary,
  PageLinkWrapper,
} from '../RecursiveMenu/styled';
import { colors } from '../../../ui/common';
import Arrow from '../Arrow';

export const MenuItem = styled.div`
  margin-right: 40px;
  font-size: 14px;

  &:last-of-type {
    margin-right: 0;
  }

  ${PageLinkWrapper} {
    min-height: 32px;
  }

  ${RecursiveMenuAccordionSummary} {
    height: 32px;
    min-height: initial;
  }
`;

export const Toolbar = withStyles({
  root: {
    margin: '0 auto',
    width: 1140,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})(MuiToolbar);

export const Accordion = withStyles({
  root: {
    fontSize: 14,
    background: 'transparent',
    color: 'inherit',
    border: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const useAccordionSummaryStyles = makeStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    padding: 0,
    minHeight: 54,
    '&$expanded': {
      minHeight: 54,
    },
  },
  content: {
    flexGrow: 0,
  },
});
export function AccordionSummary(props) {
  const classes = useAccordionSummaryStyles(props);
  const { transparent, ...rest } = props;
  return (
    <MuiAccordionSummary
      expandIcon={
        <Arrow color={transparent ? colors.color15 : colors.color17} />
      }
      className={classes.root}
      classes={{
        content: classes.content,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}
AccordionSummary.propTypes = {
  transparent: PropTypes.bool,
};
AccordionSummary.defaultProps = {
  transparent: false,
};

export const AccordionDetails = withStyles({
  root: {
    display: 'block',
    padding: 24,
    position: 'absolute',
    background: '#fff',
    color: scrolledColor,
  },
})(MuiAccordionDetails);
