import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

export const MenuMainItem = styled.div`
  min-height: 54px;
  display: flex;
  align-items: center;
`;

export const Accordion = withStyles({
  root: {
    color: 'inherit',
    border: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    padding: 0,
  },
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export const TallAccordionSummary = withStyles({
  root: {
    minHeight: 54,
  },
})(AccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block',
  },
})(MuiAccordionDetails);

export const ShiftedAccordionDetails = withStyles({
  root: {
    marginLeft: 24,
  },
})(AccordionDetails);
