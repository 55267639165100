import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  getRenderLanguageButton,
  renderExternalPageLink,
  renderInternalPageLink,
} from './RecursiveMenu/render';
import NavContext from './context';

export const languages = [
  {
    name: 'nav.lang_en',
    shorten: 'EN',
    code: 'en',
  },
  {
    name: 'nav.lang_en_au',
    shorten: 'EN (AU)',
    code: 'en-AU',
  },
  {
    name: 'nav.lang_en_uk',
    shorten: 'EN (UK)',
    code: 'en-UK',
  },
  {
    name: 'nav.lang_es',
    shorten: 'ES',
    code: 'es',
  },
  {
    name: 'nav.lang_fr',
    shorten: 'FR',
    code: 'fr',
  },
  {
    name: 'nav.lang_de',
    shorten: 'DE',
    code: 'de',
  },
  {
    name: 'nav.lang_ru',
    shorten: 'RU',
    code: 'ru',
  },
];

export default function useData() {
  const intl = useIntl();
  const { onChangeLanguage } = useContext(NavContext);

  return {
    items: [
      {
        menu: {
          title: 'navbar.devices',
          items: [
            {
              name: 'navbar.family.evachill',
              link: '/evachill',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.family.evalight',
              link: '/evalight',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.family.evasmart',
              link: '/evasmart',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.family.evapure',
              link: '/evapure',
              render: renderInternalPageLink,
            },
          ],
        },
      },
      {
        menu: {
          title: 'navbar.cartridges',
          items: [
            {
              name: 'navbar.cartridges.evasmart',
              link: '/evasmart-cartridge',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.cartridges.evalight-plus',
              link: '/evalight-plus-cartridge',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.cartridges.evalight',
              link: '/evalight-cartridge',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.cartridges.evachill',
              link: '/evachill-cartridge',
              render: renderInternalPageLink,
            },
          ],
        },
      },
      {
        menu: {
          title: 'navbar.accessories',
          items: [
            {
              name: 'navbar.accessories.evaaroma',
              link: '/evaaroma',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.accessories.evabottle',
              link: '/evabottle',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.accessories.evabank',
              link: '/evabank',
              render: renderInternalPageLink,
            },
            {
              name: 'navbar.accessories.evabag',
              link: '/evabag',
              render: renderInternalPageLink,
            },
          ],
        },
      },
      {
        name: 'navbar.family.all_products',
        link: '/shop',
        bold: true,
        render: renderInternalPageLink,
      },
    ],
    languages: languages.map((v) => ({
      ...v,
      render: getRenderLanguageButton(onChangeLanguage),
    })),
    support: [
      {
        name: 'nav.help_center',
        href: 'https://support.evapolar.com/',
        render: renderExternalPageLink,
      },
      {
        name: 'footer.returns',
        href: 'https://support.evapolar.com/hc/en-us/articles/115000899625-Return-Refund-policy',
        render: renderExternalPageLink,
      },
      {
        name: 'footer.technical_help',
        href: 'https://support.evapolar.com/hc/en-us/requests/new?ticket_form_id=360001043920',
        render: renderExternalPageLink,
      },
      {
        name: 'footer.customer_help',
        ...(intl.locale === 'ru'
          ? {
              link: '/service-ru',
              render: renderInternalPageLink,
            }
          : {
              href: 'https://support.evapolar.com/hc/en-us/requests/new',
              render: renderExternalPageLink,
            }),
      },
    ],
    campaigns: [
      {
        name: 'nav.campaigns1',
        href: 'https://sweetdreams.evapolar.com/',
        render: renderExternalPageLink,
      },
      {
        name: 'nav.campaigns2',
        href: 'https://evapolar.com/en/chill-the-ocean/',
        render: renderExternalPageLink,
      },
    ],
  };
}
