/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import LocalizedLink from '../../../localized-link';
import Basket from '../../Basket';
import { AppBar } from '../../AppBar';
import Hideable from '../../../../utils/hideable';
import useSwitch from '../../../../hooks/use-switch';
import { colors } from '../../../../ui/common';
import Logo from '../../../Logo';

import { Toolbar, OpenedMenuIconButton } from './styled';

export function MenuDrawer({ short, logoTo, children, transparent }) {
  const { value: open, toggle: toggleDrawer } = useSwitch(false);
  const notOnTop = useScrollTrigger({ disableHysteresis: true });
  const transparentOnTop = transparent && !notOnTop;

  return (
    <>
      <Hideable>
        <AppBar transparent={transparentOnTop}>
          <Toolbar>
            {!short && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            )}
            <LocalizedLink to={logoTo}>
              <Logo
                textColor={transparentOnTop ? colors.color15 : colors.color14}
              />
            </LocalizedLink>
            <Basket transparent={transparentOnTop} />
          </Toolbar>
        </AppBar>
      </Hideable>
      <Drawer anchor="top" open={open}>
        <div role="presentation">
          <OpenedMenuIconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <CloseIcon />
          </OpenedMenuIconButton>
          {children}
        </div>
      </Drawer>
    </>
  );
}
MenuDrawer.propTypes = {
  short: PropTypes.bool.isRequired,
  logoTo: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool,
};
MenuDrawer.defaultProps = {
  transparent: false,
};
