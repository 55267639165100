import makeStyles from '../../ui/make-styles';

import { colors } from '../../ui/common';

export const scrolledColor = colors.color01;
export const scrolledColorFilter =
  'brightness(0%) sepia(0) saturate(0) hue-rotate(0deg)';

export default makeStyles({
  navCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  navRight: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: (props) => (props.transparent ? '#fff' : scrolledColor),
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textShadow: '1px 0 0 #fff',
      background: '#0000',
      textDecoration: 'none',
    },
  },
  tabletMenuWrap: {
    color: scrolledColor,
    fontSize: 18,
    fontWeight: 600,
    margin: 40,
  },
  fixCenterAlign: {
    transform: 'translateX(15px)',
  },
});
